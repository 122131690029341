import { PaymentProccessing } from "@entities/payment/components/Forms/PaymentProccessing";
import { ShopFullClosedModal } from "@widgets/ShopFullClosedModal";

export function CheckoutPage() {
  return (
    <>
      <ShopFullClosedModal />
      <PaymentProccessing />
    </>
  );
}
