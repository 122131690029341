import Modal from "@entities/payment/components/Forms/modal";
import {
  $isRestaurantOpen,
  fetchTimeValidateFx,
} from "@features/choose-dishes/models";
import { $rus } from "@features/choose-dishes/models";
import { useStore } from "effector-react";
import { useState, useEffect } from "react";
import { $isAdressModalOpen } from "./address-modal";
import qrCode from "@assets/qr-code.png";

export function ShopFullClosedModal() {
  const isAddressOpen = useStore($isAdressModalOpen);

  const isRus = useStore($rus);
  const isOpen = useStore($isRestaurantOpen);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  useEffect(() => {
    fetchTimeValidateFx();
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setIsWarningModalOpen(true);
    }
  }, [isOpen]);

  return (
    <Modal
      showClose
      open={!isAddressOpen && isWarningModalOpen}
      onClose={() => setIsWarningModalOpen(false)}
    >
      <article className="flex flex-col justify-items-start items-start p-5 sm:p-8 bg-light min-h-screen md:min-h-0 max-w-3xl md:rounded-2xl">
        <div>
          <div className="text-body font-bold text-xl mb-4 sm:mb-6 pr-10">
            Ресторан закрыт
          </div>
          <p className="mb-2">Дорогие гости!</p>
          <p>Мы временно закрыли свои двери на ремонт, чтоб вернуться обновлёнными, но по-прежнему вкусными!</p>
          <p>Мы стремимся быть уютным и любимым рестораном!</p>
          <p>О всех дальнейших новостях мы будем сообщать в наших социальных сетях!</p>
          <p className="mt-2">До скорой встречи!</p>
          <p>С любовью, Ocean Basket!</p>
          <img style={{'max-width': "200px"}} src={qrCode} />
        </div>
      </article>
    </Modal>
  );
}
